/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';

import Layout from 'components/Layout';
import Meta from 'components/Meta';
import { nanoid } from 'nanoid';
import { Link } from 'gatsby';
import NewFaq from '../components/NewFaq/NewFaq';
import TrustedBy from '../components/TrustedBy';

import 'styles/get-started.scss';
const faqData = [
  {
    q: 'Are there any integrations available in ruttl?',
    a: (
      <>
        Currently we have integrated with Jira, Slack, Trello, ClickUp, Zapier,
        asana and Unsplash. We also have our custom webhook, in case you’d like
        to integrate with any other work tool of your preference. In the future,
        we also plan to integrate with many more tools. For more details, please
        visit our
        <Link to="/integrations/">&nbsp;integrations&nbsp;</Link>
        page.
      </>
    ),
  },
  {
    q: 'Is there a public roadmap available?',
    a: (
      <>
        Yes, we have our public roadmap. Here’s the
        <a
          href="https://trello.com/b/D9T51EZX"
          target="_blank"
          rel="noopener noreferrer">
          &nbsp;link&nbsp;
        </a>
        to the same.
      </>
    ),
  },
  {
    q: 'Do you support task management inside your platform?',
    a: (
      <>
        No, ruttl does not support task management. If you’re looking for the
        same, we would recommend checking out
        <a href="https://brutask.com" target="_blank" rel="noopener noreferrer">
          &nbsp;Brutask&nbsp;
        </a>
        . It is a simple online to-do list for individuals and small teams. Add,
        prioritize and time box your tasks to help improve your productivity.
      </>
    ),
  },
  {
    q: 'Will I get recommendations on my website’s look and feel?',
    a: (
      <>
        ruttl will not be able to recommend anything about your website’s look
        and feel. We would suggest you to please contact
        <a href="https://brucira.com" target="_blank" rel="noopener noreferrer">
          &nbsp;Brucira&nbsp;
        </a>
        by mailing us at
        <a
          href="mailto:hello@brucira.com."
          target="_blank"
          rel="noopener noreferrer">
          &nbsp;hello@brucira.com.&nbsp;
        </a>
      </>
    ),
  },
].map(({ q, a }) => ({
  q,
  a,
  uuid: nanoid(),
}));

const GetStarted = () => (
  <Layout hideTopBar getStartedPage>
    <Meta
      title="Ruttl Suite of products 🌐 | ruttl features"
      description="Try ruttl’s suite of products for free"
      url="https://ruttl.com/features/"
    />
    <main>
      <section className="hero-section">
        <div className="container">
          <h1 className="center">Try ruttl’s suite of products for free</h1>
          {/* <p className="center" style={{ marginBottom: '50px' }}>
            Here are some of the most frequent questions we get asked.
            <br />
            Got more questions? Visit our&nbsp;
            <a href="http://" target="_blank" rel="noopener noreferrer">
              help center
            </a>
          </p> */}
        </div>
        <div className="container">
          <div className="flex-2 flex flex-wrap align-items-start justify-content-center">
            <div className="wrapper">
              <div className="card-single">
                <div className="card-block">
                  <div className="card-icon">
                    <img
                      loading="lazy"
                      src="/assets/img/website-feedback-icon.svg"
                      alt="Website Feedback Tool icon"
                      title="Website Feedback Tool icon"
                    />
                  </div>
                  <h3>Website Feedback</h3>
                  <p>
                    Comment directly on live website and make real-time CSS
                    edits to give precise feedback
                  </p>
                  <a
                    href="https://web.ruttl.com/signup"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="button">
                    Get started
                  </a>
                  <div className="view-price">
                    <a href="/website-feedback-tool-pricing">View pricing</a>
                  </div>
                </div>
              </div>
            </div>

            <div className="wrapper">
              <div className="card-single">
                <div className="card-block">
                  <div className="card-icon">
                    <img
                      loading="lazy"
                      src="/assets/img/mobile-app-feedback-icon.svg"
                      alt="Mobile App Feedback icon"
                      title="Mobile App Feedback icon"
                    />
                  </div>
                  <h3>Mobile App Feedback</h3>
                  <p>
                    Mark issues directly on mobile application, organise
                    feedback using our internal workflow
                  </p>
                  <a
                    href="https://mobile.ruttl.com/signup"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="button">
                    Get started
                  </a>
                  <div className="view-price">
                    <a href="/mobile-app-feedback-pricing">View pricing</a>
                  </div>
                </div>
              </div>
            </div>

            <div className="wrapper">
              <div className="card-single">
                <div className="card-block">
                  <div className="card-icon">
                    <img
                      loading="lazy"
                      src="/assets/img/customer-feedback-icon.svg"
                      alt="Customer Feedback icon"
                      title="Customer Feedback icon"
                    />
                  </div>
                  <h3>Customer Feedback</h3>
                  <p>
                    Chat with your website users in real time & resolve their
                    issues with your team
                  </p>
                  <a
                    href="https://chat.ruttl.com/signup"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="button">
                    Get started
                  </a>
                  <div className="view-price">
                    <a href="/customer-feedback-tool-pricing">View pricing</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div style={{ paddingTop: 80 }}>
        <TrustedBy />
      </div>

      <NewFaq data={faqData} />
    </main>
  </Layout>
);

export default GetStarted;
